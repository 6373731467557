import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Bn2 from '@/assets/img/tmp/involve/party_bn2@2x.png';
import Bn2M from '@/assets/img/tmp/involve/party_bn2M@2x.png';
import Bn1 from '@/assets/img/tmp/involve/party_bn3@2x.png';
import Bn1M from '@/assets/img/tmp/involve/party_bn3M@2x.png';
import Bg from '@/assets/img/tmp/involve/party_detail_bg.png';
import BgM from '@/assets/img/tmp/involve/party_detail_bgM@2x.png';
import NullImage1 from '@/assets/img/tmp/no_img1@2x.png';
import NullImage2 from '@/assets/img/tmp/no_img2@2x.png';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import Button from '@/components/Button';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import Image from '@/components/Image';
import PartyLayerPopup from '@/components/Layer/PartyLayerPopup';
import LinkSafe from '@/components/LinkSafe';
import NewsBoardSearchBar from '@/components/NewsBoardSearchBar';
import TagPagination from '@/components/Pagination/TagPagination';
import Section from '@/components/Section';
import { Tit } from '@/components/Titles';
import { BOARD_KIND_PARTY } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { getCurrentPageInfo, PageInfo } from '@/helpers/PaginationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import dayjs from 'dayjs';
import { navigate, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const SearchRow = styled(Row)`
  margin-bottom: 96px;
  ${breakpoint(`mobile`)} {
    margin-bottom: 72px;
  }
`;
const SectionTop = styled(Section)`
  background: url(${Bg}) center top no-repeat;
  background-size: 1920px;
  .list-flex {
    & > li {
      margin-bottom: 64px;
    }
    .dec-wrap {
      background: transparent;
    }
    .date {
      margin-top: 48px;
    }
  }

  ${breakpoint(`mobile`)} {
    background-image: url(${BgM});
    background-size: 100% auto;
  }
`;
const SectionBn = styled(Section)`
  .bn {
    display: block;
    margin-bottom: 64px;
    height: auto;
    width: 100%;
    border: 0;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
    ${Image} {
      display: block;
      width: 100%;
    }
  }
  ${breakpoint(`tablet`)} {
    .bn {
      margin-bottom: 48px;
    }
  }
`;

const NewsImage = styled.div`
  overflow: hidden;
  position: relative;

  .img-box {
    width: 100%;
    padding-top: 100%;
  }

  .card-img {
    max-width: none;
    width: auto;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    max-height: none;
    min-height: 100%;
  }

  .img-box,
  .card-img {
    transition: transform 1.2s;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
    border: 0px solid transparent;
    transition: border 0.2s;
  }

  .link-item {
    display: block;
  }

  /* if motion 경우 (최근소식 모션 없음) */
  &:hover {
    .img-box,
    .card-img {
      ${({ motion }) => {
        if (motion) {
          return `transform: translate(-50%, -50%) scale(1.2);`;
        }
        return ``;
      }}
    }
    &::before {
      ${({ motion }) => {
        if (motion) {
          return `border: 8px solid #1cabe2;`;
        }
        return ``;
      }}
    }
  }
`;
const NewsBody = styled.div`
  background: #fff;
  padding-top: 32px;

  strong {
    line-height: 1.6;
    min-height: 64px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #2d2926;
    text-align: left;
    word-break: break-all;
  }

  ${breakpoint(`tablet`)} {
    padding-top: 16px;
    strong {
      min-height: 50px;
    }
  }
`;
const NewsDate = styled.span`
  display: block;
  margin-top: 16px;
`;
const NewText = styled.span`
  font-size: 14px;
  color: #1cabe2;
  font-weight: normal;
  display: inline-block;
  margin: -2px 0 0 8px;
  vertical-align: middle;
`;
const NewsCardLayout = styled.ul`
  & > li {
    width: 25%;

    ${NewsImage} {
      background: url(${NullImage1}) center no-repeat;
      background-size: cover;
      height: 256px;
      ${breakpoint(640)} {
        height: calc(50vw - 32px);
      }

      .img-box {
        padding-top: 100%;
        height: 0;
      }
    }

    &:nth-child(2n + 0) {
      ${NewsImage} {
        background-image: url(${NullImage2});
      }
    }
  }

  ${breakpoint(`tablet`)} {
    margin: 0 -12px;
    li {
      padding: 0 12px;
      width: 33.33%;
    }
  }
  ${breakpoint(`mobile`)} {
    li {
      width: 50%;
    }
  }
`;

const PartyForUnicefList: React.FC<PageProps> = ({ location }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [PopupTitle, setPopupTitle] = useState(``);
  const [PopupSid, setPopupSid] = useState<PopupData | string | number>(``);

  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);

  const qs = queryString.parse(location.search);
  // 검색중일때 현재 페이지 데이터
  const searchCurrentPage = qs.currentPage ? Number(qs.currentPage) : 1;
  const keyword = qs.keyword as string;

  const [nodes, setNodes] = useState<BoardVo[]>([]);
  const [currentPageInfo, setCurrentPageInfo] = useState<PageInfo>();

  const date = new Date();
  const year = date.getFullYear();
  const month = `0${1 + date.getMonth()}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const today = year + month + day;

  // party 목록 로드
  const loadPartyList = useCallback(async () => {
    try {
      const {
        totalCount,
        data,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_PARTY],
        page: Number(location.pathname.split('/').at(-1)),
        pagePerCount: 12,
      });

      // 페이지네이션 정보
      const partyTotalCount = totalCount as any;
      const pageInfo = getCurrentPageInfo({
        totalItemCount: partyTotalCount,
        baseUri: '/involve/individual/party-for-unicef-detail',
        pageGroupSize: 5,
        currentPage: Number(location.pathname.split('/').at(-1)),
        itemsPerPage: 12,
      });
      setCurrentPageInfo(pageInfo);

      // party 목록
      const partyList = data as any;
      setNodes(partyList);
    } catch (e) {
      console.error(e);
    }
  }, [location.pathname]);

  // 동적 데이터 로드
  const loadPage = useCallback(
    async (targetPage: number, targetKeyword?: string) => {
      const {
        resultCode,
        data: _data,
        totalCount,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_PARTY],
        keyword: targetKeyword,
        page: targetPage,
        pagePerCount: currentPageInfo?.itemsPerPage,
      });

      const newData = _data as any;

      if (resultCode === `success`) {
        const boardPageInfo = getCurrentPageInfo({
          totalItemCount: totalCount || 0,
          currentPage: targetPage,
          pageGroupSize: currentPageInfo?.pageGroupSize,
          itemsPerPage: currentPageInfo?.itemsPerPage,
          baseUri: currentPageInfo?.baseUri,
          keyword: targetKeyword,
        });

        setNodes(newData);
        setCurrentPageInfo(boardPageInfo);
      }
    },
    [
      currentPageInfo?.baseUri,
      currentPageInfo?.itemsPerPage,
      currentPageInfo?.pageGroupSize,
    ],
  );

  useEffect(() => {
    // keyword 변경되면 업데이트
    if (keyword) {
      loadPage(searchCurrentPage, keyword);
    } else {
      loadPartyList();
    }
  }, [searchCurrentPage, keyword, loadPage, loadPartyList]);

  return (
    <LayoutWithTitle location={location} title="파티이야기">
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SearchRow searchBar justifyContent="flex-end">
            <NewsBoardSearchBar
              initialKeyword={keyword}
              onSearch={(value) => {
                navigate(
                  `${currentPageInfo?.baseUri}?${queryString.stringify({
                    keyword: value,
                  })}`,
                );
              }}
            />
          </SearchRow>
          <NewsCardLayout className="flex list-flex">
            {nodes.map((row) => (
              <li key={row.boardIndexNumber}>
                <LinkSafe
                  to={`/involve/individual/party-for-unicef-detail/${row.boardIndexNumber}`}
                  className="link-item"
                >
                  <NewsImage>
                    <Image
                      className="card-img"
                      pcSrc={row.imgAttGrpNoTn}
                      mobileSrc={row.imgAttGrpNoTn}
                    />
                  </NewsImage>
                  <NewsBody className="dec-wrap">
                    <Tit size="s4-1">
                      {row.subject}
                      {today -
                        dayjs(row.firstRegisterDate).format(`YYYYMMDD`) <=
                      7 ? (
                        <NewText>new</NewText>
                      ) : null}
                    </Tit>
                    <NewsDate className="date">
                      {row.firstRegisterDate}
                    </NewsDate>
                  </NewsBody>
                </LinkSafe>
              </li>
            ))}
          </NewsCardLayout>
          <TagPagination {...currentPageInfo} />
        </Container>
      </SectionTop>

      <SectionBn className="by-sub-main-layout">
        <Container>
          <Button
            onClick={() => {
              open();
              setPopupTitle(`Party for UNICEF 신청`);
              setPopupSid(1);
            }}
            className="bn"
          >
            <Image pcSrc={Bn1} mobileSrc={Bn1M} />
          </Button>
          <Button
            onClick={() => {
              open();
              setPopupTitle(`Party for UNICEF 활동후기`);
              setPopupSid(2);
            }}
            className="bn"
          >
            <Image pcSrc={Bn2} mobileSrc={Bn2M} />
          </Button>
        </Container>
      </SectionBn>
      <PartyLayerPopup
        isOpen={popupOpen}
        PopTitle={PopupTitle}
        PopSid={PopupSid}
        onRequestClose={close}
      />
    </LayoutWithTitle>
  );
};

export default PartyForUnicefList;
